import * as React from 'react';
import { Link } from 'gatsby';
import { Page } from '../components/page';
import { RichText } from '../components/richt_text';

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <Page title="Seite nicht gefunden">
      <RichText>
        <h1>404 - Diese Seite existiert nicht</h1>
        <div>
          Leider konnten wir die gesuchte Seite nicht finden. Dies könnte daran liegen, dass Sie
          eine falsche oder veraltete URL aufgerufen haben, oder wir die betreffende Seite in der
          Zwischenzeit archiviert oder umbenannt haben.
          <br />
          <br />
          <Link to="/">Zurück zur Startseite</Link>
        </div>
      </RichText>
    </Page>
  );
};

export default NotFoundPage;
